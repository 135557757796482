import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController) {
  }
  async error(error: any) {
    const alert = await this.alertController.create({
      header: 'Ошибка',
      message: error.message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async showMessage(message, header?) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }
}
