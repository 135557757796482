import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Building} from '../../shared/services/buildings.types';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TicketPriority, TicketType} from '../../shared/services/ticket-types.types';
import {finalize, switchMap} from 'rxjs/operators';
import {BuildingsService} from '../../shared/services/buildings.service';
import {forkJoin, lastValueFrom, Observable, of, Subject} from 'rxjs';
import {AlertService} from '../../shared/services/alert.service';
import {TicketTypesService} from '../../shared/services/ticket-types.service';
import {TicketsService} from '../../tabs/tickets/tickets.service';
import {NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {fileSizeValidator, FileSizeValidatorResults, isImageFile, previewUrl} from '../../utils';
import {UserService} from '../../shared/services/user.service';
import { environment } from '../../../environments/environment';
import { Ticket, TicketDetails } from '../../tabs/tickets/tickets.types';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.page.html',
  styleUrls: ['./add-ticket.page.scss'],
})
export class AddTicketPage implements OnInit, OnDestroy {

  title: string;
  loading = true;
  building: Building;
  ticket: TicketDetails;
  ticketType: TicketType;
  formlyFields: FormlyFieldConfig[] = [];
  formlyForm: FormGroup = new FormGroup({});
  formlyModel;
  statuses: any[] = [];
  files: { title: string; file?: File; url?: SafeUrl; isImage: boolean }[] = [];
  canAddAttachments = this.userService.canAddAttachments;
  canRemoveAttachments = this.userService.canRemoveAttachments;

  unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder,
              private buildingsService: BuildingsService,
              private ticketTypesService: TicketTypesService,
              private userService: UserService,
              private service: TicketsService,
              private route: ActivatedRoute,
              private router: Router,
              private domSanitizer: DomSanitizer,
              private alert: AlertService) {
  }

  async ngOnInit() {
    // TODO make it in single flow
    let ticketType: TicketType;
    const ticketId = this.route.snapshot.paramMap.get('ticketId')
    if (ticketId) {
      try {
        this.ticket = await lastValueFrom(this.service.get(ticketId));
        ticketType = this.ticket.ticketType
      } catch (e) {
        this.alert.error(e);
      }
    }
    forkJoin([
      this.buildingsService.get(environment.building),
      ticketType ? of(ticketType) : this.ticketTypesService.get(this.route.snapshot.paramMap.get('ticketTypeId'))
    ]).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: ([building, ticketType]) => {
        this.building = building;
        this.ticketType = ticketType;
        if (ticketType.form?.fields?.length > 0) {
          this.formlyFields = ticketType.form.fields;
        } else {
          console.error('no fields in ticketType form');
        }
        if (ticketType.statuses?.length > 0) {
          this.statuses = ticketType.statuses;
        }
        if (!ticketType.form?.fields?.length) {
          console.error('no fields in ticketType form');
        }

        if (this.ticket) {
          this.formlyModel = this.ticket.form;
          // this.formlyForm.patchValue(this.ticket.form);
          this.title = this.ticket.subject;
          this.files = this.ticket.attachments.map(file => ({
            ...file,
            isImage: isImageFile(file.title)
          }));
        } else {
          this.formlyModel = {};
          this.title = ticketType.name;
        }
      },
      error: this.handleError,
    });
  }

  submit(): void {
    if (!this.formlyForm.valid) {
      this.formlyForm.markAllAsTouched();
      this.alert.showMessage('Пожалуйста, заполните указанные поля', 'Внимание');
      return;
    }
    this.formlyForm.disable();

    const basicData = {
      subject: null,
      description: null,
      dueDate: null,
      form: this.formlyModel,
      building: this.building._id,
      country: this.building.country,
      company: this.building.company,
      ticketType: this.ticketType._id,
      shop: null,
    }
    if (this.userService.currentResident?._id) {
      basicData.shop = this.userService.currentResident._id;
    }

    if (this.ticket) {
      this.service.update(this.ticket._id, basicData).pipe(
        switchMap(this.attachFiles),
      ).subscribe({
        next: res => {
          this.alert.showMessage('Информация о заявке обновлена', 'Успех!');
          this.router.navigateByUrl('tabs/tickets');
          this.service.reloadSubject.next();
        },
        error: this.handleError,
      })
    } else {
      const data = {
        ...basicData,
        statusId: this.statuses[0].id,
        priority: TicketPriority.NORMAL,
      }

      this.service.add(data).pipe(
        switchMap(this.attachFiles),
      ).subscribe({
        next: res => {
          this.alert.showMessage('Заявка добавлена', 'Успех!');
          this.router.navigateByUrl('tabs/tickets');
          this.service.reloadSubject.next();
        },
        error: this.handleError,
    });
    }
  }

  // TODO Ionic file opener for mobile devices. Allow only images and videos
  async addFiles($event: Event): Promise<void> {
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const array = Array.from(target.files);
    for (const file of array) {
      if (fileSizeValidator(file, true) !== FileSizeValidatorResults.VALID) {
        this.alert.error('Некорректный размер файла!');
        return;
      } else {
        const isImage = isImageFile(file.name);
        const {url} = await previewUrl(file);
        this.files.push({
          file,
          isImage,
          url: isImage ? this.domSanitizer.bypassSecurityTrustUrl(url) : null,
          title: file.name,
        });
      }
    }
  }

  removeFile(position: number): void {
    this.files.splice(position, 1);
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private handleError = (e: Error) => {
    this.alert.error(e);
    this.formlyForm.enable();
  }

  private attachFiles = (ticket: Ticket): Observable<any> => {
    const attachmentObservables: Observable<any>[] = [];
    this.files.forEach(file => {
      if (file.file) {
        const attachmentsFormData = new FormData();
        attachmentsFormData.append('file', file.file, file.file.name);
        attachmentsFormData.append('title', file.file.name);
        attachmentObservables.push(this.service.uploadAttachment(ticket._id, attachmentsFormData));
      }
    });
    return attachmentObservables.length > 0 ? forkJoin(attachmentObservables) : of([]);
  }

}
