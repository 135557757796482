import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class IndexGuard  {
  private storage = inject(Storage);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const tokenFromQuery = route.queryParamMap.get('token');
    if (tokenFromQuery) {
      await this.storage.set('x-access-token', tokenFromQuery);
    }
    const token = tokenFromQuery || await this.storage.get('x-access-token');
    this.router.navigateByUrl(token ? '/tabs/tickets' : '/auth/login');
    return true;
  }

}
