import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-text',
  template: `
   <ion-item lines="none">
     {{ props.label }}
   </ion-item>
 `,
})
export class FormlyText extends FieldType {}
