// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.file img + .name {
  margin-left: 16px;
}
.file .name {
  flex: 1;
  margin-right: 16px;
}
.file + .file {
  margin-top: 16px;
}
.file .icon {
  margin-right: 16px;
}

.history .owner {
  font-weight: bold;
}
.history .action {
  margin: 8px 0;
}
.history .date {
  font-size: 14px;
  color: gray;
}
.history + .history {
  margin-top: 8px;
  border-top: 1px solid lightgray;
  padding-top: 8px;
}

.code {
  text-align: center;
}

.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/ticket-details/ticket-details.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,OAAA;EACA,kBAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;;AASE;EACE,iBAAA;AANJ;AASE;EACE,aAAA;AAPJ;AAUE;EACE,eAAA;EACA,WAAA;AARJ;AAWE;EACE,eAAA;EACA,+BAAA;EACA,gBAAA;AATJ;;AAaA;EACE,kBAAA;AAVF;;AAaA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAVF","sourcesContent":[".file {\n  img {\n    width: 80px;\n    height: 80px;\n    object-fit: contain;\n  }\n\n  img + .name {\n    margin-left: 16px;\n  }\n\n  .name {\n    flex: 1;\n    margin-right: 16px;\n  }\n\n  & + .file {\n    margin-top: 16px;\n  }\n\n  .icon {\n    margin-right: 16px;\n  }\n}\n\n.history {\n  .owner {\n    font-weight: bold;\n  }\n\n  .action {\n    margin: 8px 0;\n  }\n\n  .date {\n    font-size: 14px;\n    color: gray;\n  }\n\n  & + .history {\n    margin-top: 8px;\n    border-top: 1px solid lightgray;\n    padding-top: 8px;\n  }\n}\n\n.code {\n  text-align: center;\n}\n\n.phone {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
