import { AfterViewInit, Directive, ElementRef, OnDestroy, inject, output, input } from '@angular/core';
import Viewer from 'viewerjs';

// taken from https://github.com/winteragency/ngx-viewer/blob/master/projects/ngx-viewer/src/lib/ngx-viewer.directive.ts
@Directive({
  selector: '[appImageViewer]'
})
export class ImageViewerDirective implements AfterViewInit, OnDestroy  {
  private elementRef = inject(ElementRef);
  viewerOptions = input<Viewer.Options>({});

  viewerReady = output<Event>();
  viewerShow = output<Event>();
  viewerShown = output<Event>();
  viewerHide = output<Event>();
  viewerHidden = output<Event>();
  viewerView = output<Event>();
  viewerViewed = output<Event>();
  viewerZoom = output<Event>();
  viewerZoomed = output<Event>();

  instance: Viewer;

  private nativeElement: HTMLElement;

  constructor() {
    this.nativeElement = this.elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.initViewer();
  }

  private initViewer(): void {
    if (this.instance) {
      this.instance.destroy();
    }

    this.instance = new Viewer(this.nativeElement, {
      // Transitions currently break the Viewer when running optimizations during ng build (i.e in prod mode)
      // TODO: Find a fix for this so we don't have to force disable transitions
      transition: false,
      ...this.viewerOptions()
    });

    this.nativeElement.addEventListener('ready', event => this.viewerReady.emit(event), false);
    this.nativeElement.addEventListener('show', event => this.viewerShow.emit(event), false);
    this.nativeElement.addEventListener('shown', event => this.viewerShown.emit(event), false);
    this.nativeElement.addEventListener('hide', event => this.viewerHide.emit(event), false);
    this.nativeElement.addEventListener('hidden', event => this.viewerHidden.emit(event), false);
    this.nativeElement.addEventListener('view', event => this.viewerView.emit(event), false);
    this.nativeElement.addEventListener('viewed', event => this.viewerViewed.emit(event), false);
    this.nativeElement.addEventListener('zoom', event => this.viewerZoom.emit(event), false);
    this.nativeElement.addEventListener('zoomed', event => this.viewerZoomed.emit(event), false);
  }

  public ngOnDestroy(): void {
    if (this.instance) {
      this.instance.destroy();
    }
  }
}
