export enum TicketHistoryTypes {
  TICKET_CREATED = 'ticket:created',
  TICKET_STATUS_SET = 'ticket:status:set',
  TICKET_SHARING_UPDATE = 'ticket:sharing:update',
  TICKET_ASSIGNEE_SET = 'ticket:assignee:set',
  TICKET_PRIORITY_SET = 'ticket:priority:set',
  TICKET_ATTACHMENT_ADDED = 'ticket:attachment:added',
  TICKET_ATTACHMENT_REMOVED = 'ticket:attachment:removed',
  TICKET_STATUS_APPROVED = 'ticket:status:approved',
  TICKET_COPIED_ON_REJECT = 'ticket:copiedOnReject',
  TICKET_COPIED_TO_NEW = 'ticket:copiedToNew',
  TICKET_UPDATED = 'ticket:updated',
}

export const ticketHistoryActions = {
  [TicketHistoryTypes.TICKET_CREATED]: 'Создана заявка',
  [TicketHistoryTypes.TICKET_SHARING_UPDATE]: 'Обновлена публичная ссылка:',
  [TicketHistoryTypes.TICKET_STATUS_SET]: 'Изменен статус:',
  [TicketHistoryTypes.TICKET_ASSIGNEE_SET]: 'Изменен ответственный:',
  [TicketHistoryTypes.TICKET_PRIORITY_SET]: 'Установлен приоритет:',
  [TicketHistoryTypes.TICKET_ATTACHMENT_ADDED]: 'Добавлено вложение:',
  [TicketHistoryTypes.TICKET_ATTACHMENT_REMOVED]: 'Удалено вложение:',
  [TicketHistoryTypes.TICKET_COPIED_ON_REJECT]: 'Скопирован из отмененной заявки',
  [TicketHistoryTypes.TICKET_COPIED_TO_NEW]: 'После отмены информация скопирована в новую заявку',
  [TicketHistoryTypes.TICKET_UPDATED]: 'Информация отредактирована',
};
