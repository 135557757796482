import { Injectable } from '@angular/core';
import {Ticket, TicketDetails} from './tickets.types';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  public readonly reloadSubject: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<TicketDetails> {
    return this.http.get<TicketDetails>(`/ticket/${id}?client=true`);
  }

  add(data): Observable<Ticket> {
    return this.http.post<Ticket>('/ticket', data);
  }

  update(id: string, data): Observable<Ticket> {
    return this.http.put<Ticket>(`/ticket/${id}`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`/ticket/${id}`);
  }

  getMyTickets(
    building: string,
    limit: number,
    page: number,
    options: { my?: boolean; needsApproval?: boolean; search?: string }): Observable<{totalCount: number; data: Ticket[]}> {
      const params: any = {
        building,
        limit,
        page,
        ...options,
      };
      if (!params.search) {
        delete params.search;
      }
    return this.http.get<{totalCount: number; data: Ticket[]}>('/ticket', {
      params,
    });
  }

  addComment(id: string, comment: string): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(`/ticket/${id}/comment`, {
      comment,
    });
  }

  uploadAttachment(ticketId: string, formData: any): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(`/ticket/${ticketId}/attach`, formData);
  }

  changeStatus(ticketId: string, data): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(`/ticket/${ticketId}/status`, data);
  }

  approveStatus(ticketId: string, roleId): Observable<{success: boolean}> {
    return this.http.post<{success: boolean}>(`/ticket/${ticketId}/approve`, {roleId});
  }
}
