import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertService} from './services/alert.service';
import {FacadePipe} from './pipes/facade.pipe';
import {LoadingComponent} from './loading/loading.component';
import {IonicModule} from '@ionic/angular';
import { ImageViewerDirective } from './directives/image-viewer.directive';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
]

@NgModule({
  declarations: [
    FacadePipe,
    LoadingComponent,
    ImageViewerDirective,
  ],
  providers: [
    AlertService,
  ],
  imports: [
    ...modules,
    IonicModule,
  ],
  exports: [
    ...modules,
    FacadePipe,
    LoadingComponent,
    ImageViewerDirective,
  ]
})
export class SharedModule {
}
