import {Component, OnInit, inject, viewChild } from '@angular/core';
import {TicketDetails} from '../tabs/tickets/tickets.types';
import {ActivatedRoute} from '@angular/router';
import {TicketsService} from '../tabs/tickets/tickets.service';
import {FormControl, Validators} from '@angular/forms';
import {finalize, mergeMap} from 'rxjs/operators';
import {AlertService} from '../shared/services/alert.service';
import {UserService} from '../shared/services/user.service';
import {forkJoin} from 'rxjs';
import {ABILITIES_SUBJECTS, ABILITY_ACTIONS, User} from '../shared/services/user.types';
import {AlertController, IonModal, ModalController} from '@ionic/angular';
import {TicketTypeStatus, TicketTypeStatusGroup} from '../shared/services/ticket-types.types';
import {isImageFile} from '../utils';
import {FormlyControlTypes} from '../shared/formly/formly.types';
import {SafeUrl} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import { TicketHistoryTypes, ticketHistoryActions } from './ticket-details.types';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private service = inject(TicketsService);
  private alert = inject(AlertService);
  private ionAlert = inject(AlertController);
  public userService = inject(UserService);
  public modalController = inject(ModalController);
  modal = viewChild(IonModal);
  ticket: TicketDetails;
  user: User;
  commentControl: FormControl = new FormControl<string>(null, [Validators.required]);
  loading = true;
  addingComment = false;
  showButtons = false;
  submittedForm: { name: string; value: any; type: FormlyControlTypes }[] = [];
  files: { title: string; url?: SafeUrl; isImage: boolean }[] = [];
  frontUrl = environment.publicTicketPageUrl + '/';
  showQrButton = false;
  isModalOpen = false;
  ticketHistoryTypes = TicketHistoryTypes;
  ticketHistoryActions = ticketHistoryActions;
  statusesMap: {[id: string]: TicketTypeStatus} = {};
  statusGroups = TicketTypeStatusGroup;

  ngOnInit(): void {
    this.reload();
  }

  reload(): void {
    this.loading = true;
    forkJoin([
      this.service.get(this.route.snapshot.paramMap.get('ticketId')),
      this.userService.me(),
    ]).pipe(
      finalize(() => this.loading = false),
    ).subscribe({
      next: ([ticket, user]) => {
        this.ticket = ticket;
        this.ticket.ticketType.statuses.forEach(status => this.statusesMap[status.id] = status);
        this.user = user;
        this.files = ticket.attachments.map(file => ({
          ...file,
          isImage: isImageFile(file.title)
        }));
        if (this.ticket.form) {
          const formItems = [];
          Object.keys(this.ticket.form).forEach(key => {
            const fieldInfo = this.ticket.ticketType.form.fields.find(field => field.key === key);
            formItems.push({
              ...fieldInfo,
              value: this.ticket.form[key],
            });
          });
          this.submittedForm = formItems.filter(item => item.visibility?.details);
        }
        const currentStatus = this.getCurrentStatus();
        const isManager = Boolean(user.roles.find(
          role => role.building === ticket.building._id && role.permissions.some(role => role.subject === ABILITIES_SUBJECTS.TICKET_STATUS && role.action === ABILITY_ACTIONS.MANAGE)
        ));
        this.showButtons = isManager;
        if (!isManager) {
          if (currentStatus.approvers.groups) {
            this.showButtons = user.roles.filter(
              role => role.building === ticket.building._id && currentStatus.approvers.groups.find(r => r._id === role._id)
            ).length > 0;
          }
          // on last status we show buttons only for manager
          if (currentStatus.statusGroup === TicketTypeStatusGroup.CLOSED) {
            this.showButtons = false;
          }
          // he has to be able to move DRAFT status to next
          if (currentStatus.statusGroup === TicketTypeStatusGroup.DRAFT) {
            this.showButtons = true;
          }
        }
        this.showQrButton = currentStatus.statusGroup === TicketTypeStatusGroup.CLOSED;
      },
      error: error => this.alert.error(error),
    });
  }

  addComment(): void {
    this.addingComment = true;
    this.service.addComment(this.ticket._id, this.commentControl.value).pipe(
      finalize(() => this.addingComment = false),
      mergeMap(() => this.service.get(this.ticket._id))
    ).subscribe({
      next: ticket => {
        this.ticket = ticket;
        this.commentControl.setValue(null);
      },
      error: error => this.alert.error(error),
    });
  }

  async approve(): Promise<void> {
    if (!this.userService.canSetAnyStatus && this.getCurrentStatus().parallelApproval) {
      this.checkParallelApproval();
      return;
    }
    const rejectionStatus = this.ticket.ticketType.statuses.find(status => status.statusGroup === TicketTypeStatusGroup.REJECTED);
    const hideDraft = rejectionStatus?.onReject === 'draft';
    const possibleStatuses = [];
    this.ticket.ticketType.statuses.forEach(status => {
      if (hideDraft && status.statusGroup === TicketTypeStatusGroup.DRAFT) {
        return;
      }
      possibleStatuses.push(status);
    });
    const nextStatus = this.getNextStatus();
    const alert = await this.ionAlert.create({
      header: 'Подтверждение заявки',
      message: this.userService.canSetAnyStatus
        ? 'Вы можете перевести заявку в следующие статусы:'
        : `Вы собираетесь перевести заявку в статус "${nextStatus.title}"`,
      buttons: [
        {
          text: 'Отмена',
          role: 'cancel',
        },
        {
          text: 'Подтвердить',
          role: 'confirm',
          handler: (res = nextStatus.id) => {
            this.loading = true;
            this.service.changeStatus(this.ticket._id, {statusId: res})
              .pipe(finalize(() => this.loading = false))
              .subscribe({
                next: () => {
                  this.reload();
                  this.service.reloadSubject.next();
                },
                error: error => this.alert.error(error),
            });
          },
        },
      ],
      inputs: this.userService.canSetAnyStatus
        ? possibleStatuses.map(status => ({
          label: status.title,
          type: 'radio',
          value: status.id,
        }))
        : undefined,
    });
    await alert.present();
  }

  async checkParallelApproval(): Promise<void> {
    if (this.user.roles.length === 1) {
      this.requestParallelApproval(this.user.roles[0]._id);
    } else {
      const alert = await this.ionAlert.create({
        header: 'Подтверждение заявки',
        message: 'Вы уверены, что хотите подтвердить заявку?',
        buttons: [
          {
            text: 'Отмена',
            role: 'cancel',
          },
          {
            text: 'Подтвердить',
            role: 'confirm',
            handler: roleId => this.requestParallelApproval(roleId),
          },
        ],
        inputs: this.user.roles.map(role => ({
            label: role.roleName,
            type: 'radio',
            value: role._id,
          })),
      });
      await alert.present();
    }
  }

  requestParallelApproval(roleId) {
    this.loading = true;
    this.service.approveStatus(this.ticket._id, roleId)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => {
          this.reload();
          this.service.reloadSubject.next();
        },
        error: error => this.alert.error(error),
      });
  }


  async reject(): Promise<void> {
    const alert = await this.ionAlert.create({
      header: 'Отклонение заявки',
      message: `Вы собираетесь отклонить заявку. Вы уверены?`,
      buttons: [
        {
          text: 'Отмена',
          role: 'cancel',
        },
        {
          text: 'Отклонить',
          role: 'confirm',
          handler: data => {
            if (!data?.comment || data.comment.trim().length === 0) {
              this.alert.error({
                message: 'Необходимо заполнить комментарий',
              });
              return;
            }
            this.loading = true;
            const rejectedStatus = this.getRejectedStatus();
            if (!rejectedStatus) {
              this.alert.showMessage('Ни один из статусов не имеет тип REJECTED', 'Ошибка');
              return;
            }
            this.service.changeStatus(this.ticket._id, {
              statusId: rejectedStatus.id,
              comment: data?.comment,
            })
            .subscribe({
              next: () => {
                this.reload();
                this.service.reloadSubject.next();
              },
              error: error => this.alert.error(error),
            });
          },
        },
      ],
      inputs: [
        {
          placeholder: 'Введите комментарий',
          name: 'comment',
        },
      ],
    });
    await alert.present();
  }

  // TODO: вынести в состояние компонента
  getCurrentStatus(): TicketTypeStatus {
    return this.ticket.ticketType.statuses.find(status => status.id === this.ticket.statusId);
  }

  getNextStatus(): TicketTypeStatus {
    const currentStatus = this.getCurrentStatus();
    return this.ticket.ticketType.statuses.find(status => status.id === currentStatus.flow.nextStatusId);
  }

  getPreviousStatus(): TicketTypeStatus {
    const currentStatus = this.getCurrentStatus();
    return this.ticket.ticketType.statuses.find(status => status.id === currentStatus.flow.previousStatusId);
  }

  getRejectedStatus(): TicketTypeStatus {
    return this.ticket.ticketType.statuses.find(status => status.statusGroup === TicketTypeStatusGroup.REJECTED);
  }

  isParallelApprovalCheckedByRole(roleId: string): boolean {
    const currentStatus = this.getCurrentStatus();
    if (this.ticket.approval && this.ticket.approval[currentStatus.id]?.groups) {
      return this.ticket.approval[currentStatus.id].groups.includes(roleId);
    }
    return false;
  }

  download(parent: any): void {
    const parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    if (parentElement) {
      // converts base 64 encoded image to blobData
      const blobData = this.convertBase64ToBlob(parentElement);
      // saves as image
      const blob = new Blob([blobData], {type: 'image/png'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // name of the file
      link.download = 'Tango SD QR';
      link.click();
    }
  }

  convertBase64ToBlob(base64Image: string) {
    // split into two parts
    const parts = base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], {type: imageType});
  }

  confirm() {
    this.modal().dismiss();
    this.toggleModal();
  }

  toggleModal(): void {
    this.isModalOpen = !this.isModalOpen;
  }

  showRejectButton(): boolean {
    // he will have only one button to change any status
    if (this.userService.canSetAnyStatus) {
      return false;
    }
    const currentStatusGroup = this.getCurrentStatus().statusGroup;
    return currentStatusGroup !== TicketTypeStatusGroup.REJECTED && currentStatusGroup !== TicketTypeStatusGroup.DRAFT;
  }

  copyPhone() {
    navigator.clipboard.writeText(this.ticket.shop.renterPhone);
    this.alert.showMessage('Значение скопировано в буфер обмена')
  }
}
