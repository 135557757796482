// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ql-align-center {
  text-align: center;
}
::ng-deep .ql-align-left {
  text-align: left;
}
::ng-deep .ql-align-right {
  text-align: right;
}
::ng-deep .ql-align-justify {
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/ticket-types/ticket-type-description/ticket-type-description.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":["::ng-deep {\n  .ql-align-center {\n    text-align: center;\n  }\n  .ql-align-left {\n    text-align: left;\n  }\n  .ql-align-right {\n    text-align: right;\n  }\n  .ql-align-justify {\n    text-align: justify;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
