import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TicketTypesComponent} from './ticket-types/ticket-types.component';
import {AddTicketPage} from './ticket-types/add-ticket/add-ticket.page';
import {TicketDetailsComponent} from './ticket-details/ticket-details.component';
import { AppComponent } from './app.component';
import { IndexGuard } from './shared/guards/index.guard';
import { TicketTypeDescriptionComponent } from './ticket-types/ticket-type-description/ticket-type-description.component';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  }, {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  }, {
    path: 'ticket-types',
    pathMatch: 'full',
    component: TicketTypesComponent,
  }, {
    path: 'ticket-types/:ticketTypeId/add',
    component: AddTicketPage,
  }, {
    path: 'ticket-types/:ticketTypeId/description',
    component: TicketTypeDescriptionComponent,
  }, {
    path: 'tickets/:ticketId',
    component: TicketDetailsComponent,
  }, {
    path: 'tickets/:ticketId/edit',
    component: AddTicketPage,
  }, {
    path: '',
    component: AppComponent,
    // redirects to tabs inside guard in order to get token from query
    // why not redirectTo? because it looses queryparams
    canActivate: [
      IndexGuard,
    ],
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
