export function isImageFile(fileName: string) {
  return /\.(jpe?g|png|gif|svg|webp)$/i.test(fileName);
}

export function isVideoFile(fileName: string) {
  return /\.(mp4|avi|mpe?g|wmv|webm)$/i.test(fileName);
}

export enum FileSizeValidatorResults {
  VALID,
  INVALID_SIZE,
  INVALID_FORMAT,
}

export const fileSizeValidator = (file: File, skipFormatCheck?: boolean): FileSizeValidatorResults => {
  // Максимальный размер изображения (3 Мб)
  const maxImgFileSize = 3145728;
  // Максимальный размер видео (40 Мб)
  const maxVideoFileSize = 41943040;

  const isImage = isImageFile(file.name);
  const isVideo = isVideoFile(file.name);

  if (!isVideo && !isImage && !skipFormatCheck) return FileSizeValidatorResults.INVALID_FORMAT;
  if ((isImage && file.size < maxImgFileSize) ||
    (isVideo && file.size < maxVideoFileSize) ||
    (!isVideo && !isImage && skipFormatCheck))
    return FileSizeValidatorResults.VALID;

  return FileSizeValidatorResults.INVALID_SIZE
}

export function previewUrl(file: File): Promise<{ url: string, width?: number, height?: number }> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (isImageFile(file.name)) {
        const image = new Image();
        image.src = <string>fileReader.result;

        image.onload = function () {
          resolve({
            url: <string>fileReader.result,
            width: image.width,
            height: image.height,
          });
        };
      } else {
        resolve({
          url: <string>fileReader.result,
        });
      }
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
    fileReader.readAsDataURL(file);
  });
}
