import { Component } from '@angular/core';
import { FormlyItemComponent } from '../formly-item.component';

@Component({
  selector: 'app-formly-item-in-list',
  templateUrl: './formly-item-in-list.component.html',
  styleUrls: ['./formly-item-in-list.component.scss'],
})
export class FormlyItemInListComponent extends FormlyItemComponent {

  constructor() {
    super();
  }

}
