import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyItemComponent } from './formly-item.component';
import { FormlyItemInListComponent } from './formly-item-in-list/formly-item-in-list.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [FormlyItemComponent, FormlyItemInListComponent],
  exports: [
    FormlyItemComponent,
    FormlyItemInListComponent,

  ]
})
export class FormlyItemModule {}
