import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-group',
  template: `
    <ion-item lines="none">
      <ion-label>{{props.label}}</ion-label>
    </ion-item>
    @for (f of field.fieldGroup; track f) {
      <formly-field [field]="f"></formly-field>
    }
    `,
})
export class FormlyGroupComponent extends FieldType {
}
