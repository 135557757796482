export interface DefaultTicketType {
  _id: string;
  name: string;
  form: {
    fields: any[];
  };
  buildingType: string;
  statuses: TicketTypeStatus[];
}

export enum TicketTypeStatusGroup {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  NOT_STARTED = 'NOT_STARTED',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

export enum TicketPriority {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
}

export interface TicketTypeStatus {
  id: string;
  title: string;
  flow: {
    previousStatusId: string;
    nextStatusId: string;
  };
  statusGroup: TicketTypeStatusGroup;
  approvers: {
    groups: {
      _id: string;
      roleName: string;
    }[];
  };
  visibleTo: {
    groups: string[];
  };
  parallelApproval: boolean;
  onReject?: 'draft';
}


export interface TicketType {
  _id: string;
  name: string;
  form: {
    fields: any[];
  };
  buildingType: string;
  building: string;
  statuses: TicketTypeStatus[];
  description?: string;
  labels?: {
    subject?: string;
    description?: string;
  }
}
