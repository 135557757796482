import {Injectable, inject } from '@angular/core';
import {Building} from "./buildings.types";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {
  private http = inject(HttpClient);

  getAll(company?: string): Observable<Building[]> {
    const params = {
      company,
    }
    return this.http.get<Building[]>('/mall', {
      params: company ? params : undefined,
    });
  }

  get(id: string): Observable<Building> {
    return this.http.get<Building>(`/mall/${id}`);
  }
}
