// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  width: 100%;
}

img {
  object-fit: contain;
  border-radius: 8px;
  max-height: 80px;
  max-width: 80px;
  width: 100%;
  height: 100%;
}

.name {
  margin: 0 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/ticket-types/add-ticket/add-ticket.page.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":["ion-select {\n  width: 100%;\n}\n\nimg {\n  object-fit: contain;\n  border-radius: 8px;\n  max-height: 80px;\n  max-width: 80px;\n  width: 100%;\n  height: 100%;\n}\n\n.name {\n  margin: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
