import {Injectable, inject } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TicketType} from './ticket-types.types';

@Injectable({
  providedIn: 'root'
})
export class TicketTypesService {
  private http = inject(HttpClient);

  getAll(buildings: string): Observable<TicketType[]> {
    return this.http.get<TicketType[]>('/ticket-type', {
      params: {
        buildings,
      }
    });
  }

  get(id: string): Observable<TicketType> {
    return this.http.get<TicketType>(`/ticket-type/${id}`);
  }
}
