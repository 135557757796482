import { Component } from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  styles: ['ion-button:not(.more) {margin: 0 16px} formly-field {max-width: calc(100% - 68px)}'],
  template: `
    <ion-item lines="none">
      <ion-label>{{props.label}}</ion-label>
    </ion-item>
    @for (field of field.fieldGroup; track field; let i = $index) {
      <div class="row align-items-center">
        <formly-field class="flex-1" [field]="field"></formly-field>
        <ion-button fill="clear" (click)="remove(i)">
          <ion-icon color="danger" slot="icon-only" name="trash-outline"></ion-icon>
        </ion-button>
      </div>
    }
    <ion-item>
      <ion-button class="more" type="button" color="primary" fill="clear" (click)="add()">{{props.addMoreLabel}}</ion-button>
    </ion-item>
    `,
})
export class FormlyRepeatComponent extends FieldArrayType {
}
