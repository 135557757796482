import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'facade'
})
export class FacadePipe implements PipeTransform {
  transform(facade: { [locale: string]: any }, lang: string, key?: string): string {
    if (!facade) {
      return;
    }
    return facade[lang]
      ? facade[lang][key || 'name']
      : facade['ru'] ? facade['ru'][key || 'name'] : 'Ошибка! Перевод не найден';
      //: facade['ru'] ? facade['ru'][key || 'name'] : translate('error-facade', {lang});
  }
}
