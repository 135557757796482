import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
    <ion-item>
      <ion-input type="input" [formControl]="formControl" [formlyAttributes]="field" [label]="props.label" labelPlacement="floating"></ion-input>
    </ion-item>
  `,
})
export class FormlyInput extends FieldType<FieldTypeConfig> {}
