import { Component, OnInit, inject } from '@angular/core';
import { TicketType } from '../../shared/services/ticket-types.types';
import { TicketTypesService } from '../../shared/services/ticket-types.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../shared/services/alert.service';

@Component({
  selector: 'app-ticket-type-description',
  templateUrl: './ticket-type-description.component.html',
  styleUrls: ['./ticket-type-description.component.scss'],
})
export class TicketTypeDescriptionComponent  implements OnInit {
  private ticketTypeService = inject(TicketTypesService);
  private route = inject(ActivatedRoute);
  private alertService = inject(AlertService);
  loading = true;
  ticketType: TicketType;

  ngOnInit() {
    this.ticketTypeService.get(this.route.snapshot.paramMap.get('ticketTypeId')).subscribe({
      next: ticketType => {
        this.ticketType = ticketType
        this.loading = false;
      },
      error: e => this.alertService.error(e),
    })
  }
}
