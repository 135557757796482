import {Component, inject, OnInit, input } from '@angular/core';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-formly-item',
  templateUrl: './formly-item.component.html',
  styleUrls: ['./formly-item.component.scss']
})
export class FormlyItemComponent implements OnInit {

  // TODO: types, recursion
  item = input<any>();
  seen = input(false);

  children: any[] = [];
  private alert = inject(AlertService);

  ngOnInit(): void {
    if (this.item().props.maskString) {
      this.item().props.maskString = this.item().props.maskString.replaceAll('0', 'A');
    }
    if (this.item().type === 'repeat') {
      if (!this.item().fieldArray?.fieldGroup) {
        return;
      }
      this.item().value.forEach(value => {
        if (!value) {
          return;
        }
        Object.keys(value).forEach(key => {
          const fieldInfo = this.item().fieldArray.fieldGroup.find(field => field.key === key);
          this.children.push({
            ...fieldInfo,
            value: value[key],
          });
        });
      });
    }
    if (this.item().type === 'group') {
      if (!this.item().fieldGroup) {
        return;
      }
      Object.keys(this.item().value).forEach(key => {
        const fieldInfo = this.item().fieldGroup.find(field => field.key === key);
        this.children.push({
          ...fieldInfo,
          value: this.item().value[key],
        });
      });
    }
  }

  copy(text: string) {
    navigator.clipboard.writeText(text);
    this.alert.showMessage('Значение скопировано в буфер обмена')
  }
}
