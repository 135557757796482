import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'formly-select',
  template: `
    <ion-item>
      <ion-select [label]="props.label" [formControl]="formControl" [formlyAttributes]="field" label-placement="floating">
        @for (option of props.options; track option) {
          <ion-select-option [value]="option.value">{{option.label}}</ion-select-option>
        }
      </ion-select>
    </ion-item>
    `,
 providers: [provideNgxMask(),NgxMaskDirective]
})
export class FormlySelectComponent extends FieldType {
}
