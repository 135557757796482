import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-title',
  template: `
    <ion-item lines="none">
      <h3>{{ props.label }}</h3>
    </ion-item>
 `,
})
export class FormlyTitle extends FieldType {}
