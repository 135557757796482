import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-group',
  template: `
    <ion-item lines="none">
      <ion-label>{{props.label}}</ion-label>
    </ion-item>
    <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
  `,
})
export class FormlyGroupComponent extends FieldType {
}
