import { Component, OnInit, inject } from '@angular/core';
import {TicketTypesService} from '../shared/services/ticket-types.service';
import {finalize} from 'rxjs/operators';
import {TicketType} from '../shared/services/ticket-types.types';
import {UserService} from '../shared/services/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-ticket-types',
  templateUrl: './ticket-types.component.html',
  styleUrls: ['./ticket-types.component.scss'],
})
export class TicketTypesComponent implements OnInit {
  private ticketTypesService = inject(TicketTypesService);
  private userService = inject(UserService);
  loading = true;
  ticketTypes: TicketType[];

  ngOnInit() {
    this.ticketTypesService.getAll(environment.building).pipe(
      finalize(() => this.loading = false)
    ).subscribe(data => this.ticketTypes = data);
  }

}
