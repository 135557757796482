import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, mergeMap} from 'rxjs/operators';
import {from, Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {Storage} from '@ionic/storage-angular';
import {Router} from '@angular/router';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

  constructor(private storage: Storage,
              private router: Router) {
  }

  static throwError(message: string, code?: string): Observable<never> {
    const error = new Error(message);
    error.name = code || 'BackendError';
    return throwError(error);
  }

  static fixURL(url: string): string {
    if (url.startsWith('/assets') || url.startsWith('assets')) {
      return url;
    }
    return environment.api + (url.startsWith('/') ? '' : '/') + url;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storage.get('x-access-token')).pipe(
      mergeMap(token => {
        const url = ApiInterceptorService.fixURL(req.url);
        if (req.url) {
          req = req.clone({
            url,
            ...(token ? {
              headers: new HttpHeaders({
                'X-Access-Token': token
              })
            } : {})
          });
          return next.handle(req).pipe(
            catchError(({error, status}: HttpErrorResponse) => {
              if (status === 403) {
                return ApiInterceptorService.throwError('Извините, у вас недостаточно прав для доступа к полному функционалу приложения. Пожалуйста, свяжитесь с администратором или обратитесь в службу поддержки, чтобы уточнить ваш уровень доступа.');
              }
              if (error.message) {
                return ApiInterceptorService.throwError(error.message);
              }
              return ApiInterceptorService.throwError('Server unavailable, please contact support for details');
            })
          );
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
